<template>
  <div>
    <component
      :is="currentComponent"
      :batch-operation-record-info="info"
    />
  </div>
</template>

<script>
import { findBatchOperationRecord } from '@/api/batch_operation_record'

export default {
  name: 'ShowBatchOperationRecord',
  components: {
    BatchOperationRecordAllocateCardsToTopAgentShow: () => import('@/views/batch_operation_records/detail/allocate_cards_to_top_agent/Show'),
    BatchOperationRecordAllocateCardsToAgencyTopAgentViewShow: () => import('@/views/batch_operation_records/detail/allocate_cards_to_agency/top_agent_view/Show'),
    BatchOperationRecordAllocateCardsToAgencyAgencyViewShow: () => import('@/views/batch_operation_records/detail/allocate_cards_to_agency/agency_view/Show'),
    BatchOperationRecordRenewShow: () => import('@/views/batch_operation_records/detail/renew/Show'),
    BatchOperationRecordSetUsageLimitShow: () => import('@/views/batch_operation_records/detail/set_usage_limit/Show'),
    BatchOperationRecordBatchModifyShow: () => import('@/views/batch_operation_records/detail/batch_modify/Show'),
    BatchOperationRecordCommonShow: () => import('@/views/batch_operation_records/detail/CommonShow')
  },
  data() {
    return {
      info: {},
      operation_type: ''
    }
  },
  computed: {
    batchOperationRecordId() {
      return parseInt(this.$route.params.id)
    },

    // 是否是分销商查看
    isTopAgentView() {
      return this.$store.getters.userRole === 'top_agent'
    },

    currentComponent() {
      switch (this.operation_type) {
        case 'allocate_cards_to_top_agent':
          return 'BatchOperationRecordAllocateCardsToTopAgentShow'
        case 'allocate_cards_to_agency':
          if (this.isTopAgentView) {
            return 'BatchOperationRecordAllocateCardsToAgencyTopAgentViewShow'
          } else {
            return 'BatchOperationRecordAllocateCardsToAgencyAgencyViewShow'
          }
        case 'renew':
          return 'BatchOperationRecordRenewShow'
        case 'set_usage_limit':
          return 'BatchOperationRecordSetUsageLimitShow'
        case 'batch_modify':
          return 'BatchOperationRecordBatchModifyShow'
        default:
          if (this.operation_type !== '') {
            return 'BatchOperationRecordCommonShow'
          }
          return ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findBatchOperationRecord(this.batchOperationRecordId).then((res) => {
        if (res.code === 0) {
          this.info = res.data
          this.operation_type = res.data.operation_type_slug
        }
      })
    }
  }
}
</script>
